// Step 1: Import React
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Layout } from "../../components/Layout"
import Button from '../../components/buttons/button'
import ProductBanner from "../../components/product_banners/product_banners";
import SlickProduct from "../../components/product_slick/slick_product";
import Faq from "../../components/faq/faq";
import Banner from "../../imgs/shutterstock_2003248058.png";
import IframeVIdeo from "../../components/iframe_video/iframe_video";
import "./letras.css";
import slick_items from "../../misc/letras_related/slick_items_letras.json";
import { SalWrapper } from "react-sal";
import { apiRequest } from "../../apiRquest/apiRequest";
import { Helmet } from "react-helmet";
import { graphql, Link, useStaticQuery } from "gatsby";


const pageTitle = 'Comenzá a invertir en Letras LECER, LEDES, LELINK y más.';
const pageDesc = 'Invertir en Letras es más fácil con la App Balanz. Conocé cómo funcionan las Letras del Tesoro y cómo comprar LECER, LEDES, LELINK, LELITES y LELIQ.';
let text = `Creemos que existen tantas inversiones como inversores, <span class='highlightsbold'> te presentamos Letras.</span>`;

const CedearsPage = () => {
    const [xs, setXs] = useState(false);

    const FaqsData = useStaticQuery(graphql`
        {
            allFaqs(
                filter: {category_id: {in: 17}},
                sort: {order: ASC, fields: [category_id, order]}
            ) {
                nodes {
                    category_id
                    id
                    answer
                    question
                }
            }
        }
    `);

    const [faq, setFaq] = useState({
        list: FaqsData.allFaqs.nodes
    });

    useEffect(() => {
        const handleXs = () => {
            const isXs = window.innerWidth <= 768;
            setXs(isXs);
        };

        window.addEventListener('resize', handleXs);

        handleXs();

        return () => {
            window.removeEventListener("resize", handleXs);
        };
    }, []);

    const slickSettings = {
        centerMode: false,
        slidesToShow: 5,
        dots: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 2920,
                settings: "unslick"
            },
            /*{
                breakpoint: 1200,
                settings: { slidesToShow: 5, slidesToScroll: 5, infinite: false }
            },*/
            {
                breakpoint: 992,
                settings: { slidesToShow: 3, slidesToScroll: 3, infinite: false }
            },
            {
                breakpoint: 768,
                settings: { slidesToShow: 2, slidesToScroll: 2, infinite: false }
            },
            {
                breakpoint: 576,
                settings: { slidesToShow: 1, slidesToScroll: 1, infinite: false }
            }
        ]
    };
    useEffect(() => {
        apiRequest
            .get("/v1/faq/17")
            .then((res) => {
                const data = []
                res.data.data.forEach(d => {
                    data.push({
                        question: d.question,
                        answer: d.answer
                    })

                })

                setFaq((old) => ({ ...old, list: data }));
            })
            .catch((err) => {
                console.log(err);
            })
    }, [setFaq])
    const page = (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "VideoObject",
                        "name": "¿Cómo invertir en Letras?",
                        "description": "Es muy fácil, lo hacés directamente desde nuestra plataforma. \n
                        Te recomendamos conocer tu perfil de inversor y analizar junto con tu asesor, si es necesario, cuál es la mejor opción para vos
                        ",
                        "thumbnailUrl": "https://www.youtube.com/embed/J0IwD2C-Q-w",
                        "uploadDate": "2022-07-08",
                        "duration": "PT0M55S",
                        "contentUrl": "https://balanz.com/inversiones/letras/",
                        "embedUrl": "https://balanz.com/inversiones/letras/"
                    }
                `}
                </script>
            </Helmet>

            <ProductBanner
                bannerContainerClassName="bannerLetras"
                bannerTrasparecy="bannerMask"
                productTitle="Letras"
                bannerImage={Banner}
                bannerImageClassName="cedearsBannerImage"
                textContainerClassName="textContainer"
                bannerText={text}
                bannerTextClassName="bannerText1"
                bannerImageMobile={Banner}
                mobile={false}
            />
            <SalWrapper>
                <section className="fullwidth product-description">
                    <Container>
                        <Row className="">
                            <h2
                                data-sal="fade"
                                data-sal-delay="500"
                                data-sal-duration="400"
                            >¿Qué son las Letras?</h2>
                        </Row>
                    </Container>
                    <div className="bg-lt-grey"
                        data-sal="fade"
                        data-sal-delay="100"
                        data-sal-duration="200"
                    >
                        <Container>
                            <Row className="pb-5 pb-sm-5 pb-md-4 pb-lg-5">
                                <Col xs={12} >
                                    <p className="product-text pt-2 pt-sm-3 pt-md-4 pt-lg-5">Las letras son títulos públicos emitidos con vencimiento menor a un año, por ello decimos que son inversiones de corto plazo cuya relación riesgo-retorno podemos evaluar a través de diferentes indicadores como: naturaleza del emisor, vencimiento, amortización y tasa de interés. En la mayoría de los casos se trata de bonos cupón cero. Los emisores de letras más frecuentes en Argentina son: el Estado Nacional, los Estados Provinciales y el Banco Central.
                                    </p>
                                    <div className="pt-4 text-center"
                                        data-sal="fade"
                                        data-sal-delay="300"
                                        data-sal-duration="300"
                                    >
                                        <Button id="cedears_invertir_1" variant="secondary" text="Invertir en Letras" onClick={() => { window.open("https://clientes.balanz.com/", "_blank") }} />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Container
                        data-sal="fade"
                        data-sal-delay="100"
                        data-sal-duration="200"
                    >
                        <Row className="pt-5 ">
                            <Col xs={12} md={10} lg={12} className="d-lg-flex">
                                <h4>¿Cuáles son los principales tipos de Letras negociadas en el mercado de capitales de Argentina?</h4>
                            </Col>

                        </Row>
                        <Row className="gx-5 gy-4 pt-3 pt-sm-2 pt-md-3 pt-md-4 pt-lg-5">
                            <Col xs={12} className="d-lg-flex">
                                <ol className="list-container">
                                    <li><h4>LECER</h4></li>
                                    <li><p>Las LECER son letras en pesos que pagan el capital con un interés ajustado en función de la evolución de la inflación, la cual es medida a través del Coeficiente de Estabilización de Referencia (CER).</p></li>
                                    <li><h4>LELITES</h4></li>
                                    <li><p>Las LELITES son Letras de Liquidez del Tesoro Nacional de muy corto plazo, generalmente tienen vencimiento menor a un mes y son en pesos. Únicamente las pueden comprar los Fondos Comunes de Inversión.</p></li>
                                    <li><h4>LEDES</h4></li>
                                    <li><p>Las LEDES son Letras a Descuento emitidas en pesos por el Tesoro Nacional. Su vencimiento es menor a 3 meses y se colocan a descuento, como es habitual en todo cupón cero.</p></li>
                                    <li><h4>LELIQ</h4></li>
                                    <li><p>Las LELIQ son Letras de Liquidez emitidas por el Banco Central de la República Argentina (BCRA) y resulta habitual que su vencimiento sea a 1 ó 7 días.  Únicamente las pueden comprar las entidades financieras.</p></li>
                                    <li><h4>LELINK</h4></li>
                                    <li><p>Las LELINK son letras en pesos que pagan el capital con un interés ajustado a la evolución del tipo de cambio oficial, generalmente medida a través del dólar de referencia del Banco Central (comunicación A 3500).</p></li>
                                    <li><h4>LECAP</h4></li>
                                    <li><p>Las letras de capitalización en pesos o LECAP pagan una tasa de interés fija. Los intereses se capitalizan mensualmente. Al vencimiento, pagan los intereses acumulados junto con la amortización total del capital.</p></li>
                                </ol>

                            </Col>
                        </Row>

                    </Container>
                </section>

                <section className="fullwidth razones">
                    <Container>
                        <Row>
                            <h2 className="mt-2"
                                data-sal="fade"
                                data-sal-delay="0"
                                data-sal-duration="400"
                            >5 razones para invertir en Letras</h2>

                            <SlickProduct
                                slickSettings={slickSettings}
                                list={slick_items}
                            />
                        </Row>
                    </Container>
                </section>
                <section className="fullwidth como-operar"
                    data-sal="fade"
                    data-sal-delay="0"
                    data-sal-duration="400"
                >

                    <Container>
                        <Row className="gx-5 py-3 py-md-4 py-lg-5">
                            <Col xs={12} md={6}>
                                <h2 className="mt-2">¿Cómo invertir en Letras?</h2>
                                <p className="product-text me-0 me-sm-1 me-md-3 me-lg-4 me-xl-5 mb-2 mb-lg-5">
                                    Es muy fácil, lo hacés directamente desde nuestra plataforma.
                                    <br />Te recomendamos conocer tu perfil de inversor y analizar junto con tu asesor,
                                    si es necesario, cuál es la mejor opción para vos.</p>
                                <div className="product-cta text-start text-md-start pb-5 pb-md-0 pt-2 pt-sm-3 pt-md-4 pt-lg-5">
                                    <Button id="letras_invertir_2" variant={`secondary ${xs ? 'chevron-right' : ''}`} text="Invertir en Letras" onClick={() => { window.open("https://clientes.balanz.com/", "_blank") }} />
                                </div>

                            </Col>
                            <Col xs={12} md={6} className="d-lg-flex justify-content-lg-end align-items-center">
                                <IframeVIdeo
                                    src="https://www.youtube.com/embed/J0IwD2C-Q-w"
                                    resolutionClass={null}
                                />
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="fullwidthbg pack-de-cedears-banner-2 py-4 py-md-5">
            <Container className="d-flex align-items-center justify-content-center">
                   <Row className="d-flex align-items-strech py-3 py-sm-4 py-md-4 gx-5 w-100 ">
                    <Col xs={12} md={12}>
                        <div className="section-content banner2-Title text-center"
                             data-sal="slide-up"
                             data-sal-delay="0"
                             data-sal-duration="200"
                        >
                            <h3 className="titulo me-3 me-md-3 me-lg-4 me-xl-5 mb-4" ><span className="tit-54"><strong>¿Querés empezar a invertir en Letras? </strong></span></h3>
                            
                            <span className=" subtitle  me-3 me-md-3 me-lg-4 me-xl-5" ><span className="tit-54">Abrí hoy tu cuenta de inversión en Balanz, sin costo de apertura ni mantenimiento, y accedé al mejor asesoramiento del mercado.</span></span>
                            
                        </div>
                        
                    </Col>
                    <div className="cta mt-5 mb-2 text-center pt-4 p-lg-3"
                             data-sal="slide-up"
                             data-sal-delay="300"
                             data-sal-duration="200"
                        >   
                            <Link to="https://balanz.com/abrir-cuenta-2.aspx?bam_campaign=1382">
                                <Button variant="secondary" text="Abrir Cuenta"/>
                            </Link>

                            
                        </div> 

                  </Row>
            </Container>
        </section>
                <section className="fullwidth preguntas-frecuentes">
                    <Container>
                        <Row>
                            <Faq faq={faq} customClass={'letras-faq'} />
                        </Row>
                    </Container>
                </section>
            </SalWrapper>
        </>
    )

    return (
        <main>
            <Layout title={pageTitle} description={pageDesc} childrem={page} category={"letras"}></Layout>
        </main>
    );
};

// Step 3: Export your component
export default CedearsPage;
